// import * as React from "react";
// import { useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import MobileStepper from "@mui/material/MobileStepper";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// const images = [
//    {
//       label: "San Francisco – Oakland Bay Bridge, United States",
//       imgPath:
//          "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
//    },
//    {
//       label: "Bird",
//       imgPath:
//          "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
//    },
//    {
//       label: "Bali, Indonesia",
//       imgPath:
//          "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250",
//    },
//    {
//       label: "Goč, Serbia",
//       imgPath:
//          "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
//    },
// ];

// function SwipeableTextMobileStepper() {
//    const theme = useTheme();
//    const [activeStep, setActiveStep] = React.useState(0);
//    const maxSteps = images.length;

//    const handleNext = () => {
//       setActiveStep((prevActiveStep) => prevActiveStep + 1);
//    };

//    const handleBack = () => {
//       setActiveStep((prevActiveStep) => prevActiveStep - 1);
//    };

//    const handleStepChange = (step) => {
//       setActiveStep(step);
//    };

//    return (
//       <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
//          <Paper
//             square
//             elevation={0}
//             sx={{
//                display: "flex",
//                alignItems: "center",
//                height: 50,
//                pl: 2,
//                bgcolor: "background.default",
//             }}
//          >
//             <Typography>{images[activeStep].label}</Typography>
//          </Paper>
//          <AutoPlaySwipeableViews
//             axis={theme.direction === "rtl" ? "x-reverse" : "x"}
//             index={activeStep}
//             onChangeIndex={handleStepChange}
//             enableMouseEvents
//          >
//             {images.map((step, index) => (
//                <div key={step.label}>
//                   {Math.abs(activeStep - index) <= 2 ? (
//                      <Box
//                         component="img"
//                         sx={{
//                            height: 255,
//                            display: "block",
//                            maxWidth: 400,
//                            overflow: "hidden",
//                            width: "100%",
//                         }}
//                         src={step.imgPath}
//                         alt={step.label}
//                      />
//                   ) : null}
//                </div>
//             ))}
//          </AutoPlaySwipeableViews>
//          <MobileStepper
//             steps={maxSteps}
//             position="static"
//             activeStep={activeStep}
//             nextButton={
//                <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
//                   Next
//                   {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
//                </Button>
//             }
//             backButton={
//                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
//                   {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
//                   Back
//                </Button>
//             }
//          />
//       </Box>
//    );
// }

// export default SwipeableTextMobileStepper;

import React from "react";
import carinfo from "./layouts/home/carInfo.json";
import PdCard from "./layouts/home/PdCard";
import { Box } from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useTheme, useMediaQuery } from "@mui/material";

const Tester = () => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

   const getWidth = () => {
      switch (true) {
         case isSmallScreen:
            return 1;
         case isMdScreen:
            return 2;

         default:
            return 3;
      }
   };
   const slidesToShowValue = getWidth();
   const settings = {
      dots: true,
      Infinity: true,
      speed: 1500,
      slidesToShow: slidesToShowValue,
      slidesToScroll: slidesToShowValue,
   };

   return (
      <Box width={"80%"} height={"400px"}>
         <Slider {...settings}>
            {carinfo.map((car, index) => (
               <PdCard
                  key={index}
                  title={car.title}
                  imgSrc={car.pic}
                  price={car.price}
                  info={car.info}
               />
            ))}
         </Slider>
      </Box>
   );
};

export default Tester;
