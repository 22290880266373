import React from "react";
import NavBar from "./Nav/NavBar";
import Home from "./home/Home";
import Footer from "./footar/Footer";
import { Outlet, useOutlet } from "react-router-dom";
import AppAppBar from "./Nav/SideBar";

const Index = () => {
   const hasChildren = useOutlet();

   return (
      <>
         <AppAppBar />
         {/* <NavBar /> */}
         {hasChildren ? <Outlet /> : <Home />}
         <Footer />
      </>
   );
};

export default Index;
