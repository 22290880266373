import * as React from "react";
import PropTypes from "prop-types";
import { useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import logo from "./conceptMotors.png";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CallIcon from "@mui/icons-material/Call";
import { Drawer, ListItemButton, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Directions } from "@mui/icons-material";
// import ToggleColorMode from "./ToggleColorMode"

const logoStyle = {
   width: "170px",
   height: "auto",
   cursor: "pointer",
};

const pages = [
   { name: "Home", href: "./" },
   { name: "Pick UP", href: "./" },
   { name: "Review", href: "./" },
   { name: "Contact Us", href: "./" },
];
function AppAppBar({ mode, toggleColorMode }) {
   const [open, setOpen] = React.useState(false);

   const theme = useTheme();

   const isMediumScreen = useMediaQuery(theme.breakpoints.down("600px"));

   const DrawerBox = styled(Box)(({ theme }) => ({
      [`@media (min-width: 750px)`]: {
         display: "none", // Adjusted maxHeight for custom large screens
      },
      [`@media (max-width: 750px)`]: {
         display: "flex",
      },
   }));

   const StyledBox = styled(Box)(({ theme }) => ({
      [`@media (max-width: 900px)`]: {
         display: "flex", // Adjusted maxHeight for custom large screens
         flexDirection: "column-reverse",
         gap: 0,
         alignItems: "flex-end",
      },
      [`@media (min-width: 900px)`]: {
         display: "flex", // Adjusted maxHeight for custom large screens
      },
      [`@media (max-width: 750px)`]: {
         display: "none",
      },
   }));

   const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
   };

   const scrollToSection = (sectionId) => {
      const sectionElement = document.getElementById(sectionId);
      const offset = 128;
      if (sectionElement) {
         const targetScroll = sectionElement.offsetTop - offset;
         sectionElement.scrollIntoView({ behavior: "smooth" });
         window.scrollTo({
            top: targetScroll,
            behavior: "smooth",
         });
         setOpen(false);
      }
   };

   return (
      <AppBar
         position="fixed"
         sx={{
            boxShadow: 0,
            bgcolor: "transparent",
            backgroundImage: "none",
            mt: 2,
         }}
      >
         <Container maxWidth="lg">
            <Toolbar
               variant="regular"
               sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  flexShrink: 0,
                  borderRadius: "999px",
                  bgcolor:
                     theme.palette.mode === "light"
                        ? "rgba(255, 255, 255, 0.4)"
                        : "rgba(0, 0, 0, 0.4)",
                  backdropFilter: "blur(24px)",
                  height: { sm: "104px", md: "" },
                  border: "1px solid",
                  borderColor: "divider",
                  boxShadow:
                     theme.palette.mode === "light"
                        ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                        : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
               })}
            >
               {/* LOGO display */}
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     ml: "-18px",
                     mt: "8px",
                     px: 0,
                  }}
               >
                  <img src={logo} style={logoStyle} alt="logo of sitemark" />
               </Box>
               {/* Large Screen NAv Display */}

               <StyledBox
                  sx={{
                     gap: 0.5,
                     alignItems: "right",
                     flexDirection: "",
                     flexGrow: 1,
                  }}
               >
                  <Box display={"flex"} flexGrow={1}>
                     {pages.map((obj) => (
                        <MenuItem
                           onClick={() => scrollToSection("features")}
                           sx={{ py: "6px", px: "12px" }}
                        >
                           <Typography variant="body1" color="Black" fontWeight={"bold"}>
                              {obj.name}
                           </Typography>
                        </MenuItem>
                     ))}
                  </Box>
                  {/* Display Of cell Info */}

                  <Box display={"flex"}>
                     <Box alignContent={"center"}>
                        <IconButton
                           aria-label="menu"
                           aria-controls="menu-appbar"
                           aria-haspopup="true"
                           color="inherit"
                        >
                           <CallIcon sx={{ fontSize: 38 }} />
                        </IconButton>
                     </Box>
                     <Box alignContent={"center"}>
                        <Typography variant="h6" fontWeight={"bold"}>
                           +44 7376 685270
                        </Typography>
                     </Box>
                  </Box>
               </StyledBox>
               <DrawerBox flexGrow={1} justifyContent={"flex-end"}>
                  <Button
                     variant="text"
                     color="primary"
                     aria-label="menu"
                     onClick={toggleDrawer(true)}
                     sx={{ minWidth: "30px", p: "4px" }}
                  >
                     <MenuIcon fontSize="large" style={{ color: "white" }} />
                  </Button>
                  <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                     <Box
                        sx={{
                           minWidth: "60dvw",
                           p: 2,
                           backgroundColor: "background.paper",
                           flexGrow: 1,
                        }}
                     >
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "end",
                              flexGrow: 1,
                           }}
                        >
                           {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
                        </Box>
                        {pages.map((obj, index) => (
                           <ListItemButton key={index}>
                              <ListItemText primary={obj.name} />
                           </ListItemButton>
                        ))}

                        <MenuItem onClick={() => scrollToSection("faq")}>FAQ</MenuItem>
                        <Divider />
                     </Box>
                  </Drawer>
               </DrawerBox>
            </Toolbar>
         </Container>
      </AppBar>
   );
}

AppAppBar.propTypes = {
   mode: PropTypes.oneOf(["dark", "light"]).isRequired,
   toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
