import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Index from "./layouts";
import Tester from "./tester";

const router = createBrowserRouter([
   {
      path: "/",
      element: <Index />,
      errorElement: <></>,
      children: [
         {
            path: "/tester",
            element: <Tester />,
         },
      ],
   },
]);
function Router() {
   return (
      <>
         <RouterProvider router={router} />
      </>
   );
}

export default Router;
