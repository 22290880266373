import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "./conceptMotors.png";

const openingTimes = [
   { day: "Monday", hours: "09:00 - 17:30" },
   { day: "Tuesday", hours: "09:00 - 17:30" },
   { day: "Wednesday", hours: "09:00 - 17:30" },
   { day: "Thursday", hours: "09:00 - 17:30" },
   { day: "Friday", hours: "09:00 - 17:30" },
   { day: "Saturday", hours: "09:00 - 17:30" },
   { day: "Sunday", hours: "10:00 - 16:00" },
];

const Footer = () => {
   const today = new Date().toLocaleString("en-us", { weekday: "long" });

   return (
      <Grid container sx={{ mt: "20px", backgroundColor: "rgb(248 250 252)" }} p={1}>
         <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={0} sx={{ maxWidth: "100%", backgroundColor: "rgb(248 250 252)" }}>
               <Box sx={{ p: 2 }}>
                  <Typography variant="h4">Find us</Typography>
                  <Box sx={{ width: "100%", overflow: "hidden", mt: 1 }}>
                     <iframe
                        src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d2484.4619943804296!2d-0.396956!3d51.486389!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTHCsDI5JzExLjAiTiAwwrAyMyc0OS4wIlc!5e0!3m2!1sen!2sus!4v1698339068081!5m2!1sen!2sus"
                        loading="lazy"
                        height="150" // Adjust height as needed
                        style={{ border: 0, width: "100%" }}
                        allowFullScreen=""
                     ></iframe>
                  </Box>
               </Box>
            </Paper>
         </Grid>
         <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={0} sx={{ maxWidth: "100%", backgroundColor: "rgb(248 250 252)" }}>
               <Box sx={{ p: 2 }}>
                  <Typography variant="h4">Contact</Typography>
                  <Box sx={{ display: "flex", mt: 1 }}>
                     <Box
                        display={"flex"}
                        sx={{
                           flexDirection: "column",
                           flexGrow: 1,
                           width: "100%",
                           justifyContent: "space-evenly",
                        }}
                     >
                        <Box height={60} width={90}>
                           <img src={logo} width={"150%"} />
                        </Box>
                        <Typography width={"100%"} variant="subtitle1">
                           +44 7376 685270
                        </Typography>
                     </Box>
                     <Box
                        sx={{
                           // display: "flex",
                           // flexDirection: { sm: "row-reverse" },
                           // justifyContent: "space-evenly",
                           alignItems: "start",
                           ml: { xs: 0, sm: 1 },
                           flexGrow: 2,
                           textAlign: "right",
                           width: "100%",
                        }}
                     >
                        <Typography width={"100%"} variant="h6">
                           Beckton Gas Works, Opposite Tesco Extra,
                           <br />
                           Armada Way,
                           <br />
                           Beckton,
                           <br />
                           E67FB London
                        </Typography>
                     </Box>
                  </Box>
               </Box>
            </Paper>
         </Grid>
         <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={0} sx={{ maxWidth: "100%", backgroundColor: "rgb(248 250 252)" }}>
               <Box sx={{ p: 2 }}>
                  <Typography variant="h4">Links</Typography>
                  <Box sx={{ display: "flex", flexDirection: "column", mt: 1, gap: "1rem" }}>
                     <Typography
                        component="a"
                        href="https://www.autotrader.co.uk/dealers/beckton/armada-way/concept-motors-10033773?channel=cars"
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="h5"
                     >
                        Autotrader
                     </Typography>
                     <Typography
                        component="a"
                        href=""
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="h5"
                     >
                        Privacy Policy
                     </Typography>
                  </Box>
               </Box>
            </Paper>
         </Grid>
         <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={0} sx={{ maxWidth: "100%", backgroundColor: "rgb(248 250 252)" }}>
               <Box sx={{ p: 2 }}>
                  <Typography variant="h4">Opening Times</Typography>
                  <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                     {openingTimes.map(({ day, hours }) => (
                        <Typography
                           // noWrap={true}
                           variant="h6"
                           key={day}
                           display={"flex"}
                           sx={{
                              fontWeight: today === day ? "bold" : "normal",
                              width: "100%",
                              gap: day === "Wednesday" ? 0 : 1,
                              justifyContent: "space-between",
                              maxWidth: "100%",
                           }}
                        >
                           <span>{day}</span>
                           <span>{hours}</span>
                        </Typography>
                     ))}
                  </Box>
               </Box>
            </Paper>
         </Grid>
      </Grid>
   );
};

export default Footer;
