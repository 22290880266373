import { Box, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import aboutPic from "./pics/about-us.png";

const Tester = () => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
   const isLgScreen = useMediaQuery(theme.breakpoints.down("lg"));

   return (
      <Grid container alignItems={isSmallScreen ? "none" : "center"} mt={"20px"}>
         <Grid
            item
            xs={12}
            sm={6}
            md={6}
            height={isMdScreen ? "auto" : "700px"}
            overflow={"hidden"}
         >
            <Paper elevation={0} sx={{ maxWidth: "100%", height: "auto" }}>
               <Box
                  display={"flex"}
                  sx={{
                     overflow: "hidden",
                     justifyContent: "center",
                     width: isSmallScreen ? "100%" : "700",
                  }}
               >
                  <img
                     src={aboutPic}
                     alt="About Us"
                     style={{ width: isSmallScreen ? "100%" : "auto" }}
                  />
               </Box>
            </Paper>
         </Grid>
         <Grid item xs={12} sm={6} md={6}>
            <Paper elevation={0} sx={{ maxWidth: "100%" }}>
               <Box
                  display={"flex"}
                  sx={{
                     justifyContent: "center",
                     flexDirection: "column",
                     px: "30px",
                     gap: 4,
                     alignItems: "center",
                  }}
               >
                  <Typography variant="h4" fontWeight={"bold"}>
                     Don't just dream it - drive it!
                  </Typography>
                  <Typography variant="h6">
                     Here at Concept Motors, we have a wide selection of quality used vehicles. Our
                     stocklist is updated regularly however, it is always worth giving us a call,
                     even if you don't see what you are looking for on our website.
                  </Typography>
                  <Typography variant="h6">
                     Buying a used vehicle can be a daunting experience - not to worry! Our friendly
                     staff offer a first class customer service at extremely competitive prices!
                  </Typography>
                  <Typography variant="h6">
                     For further details on any vehicle or to arrange a test drive please do not
                     hesitate to give us a call and a friendly member of our sales team will be more
                     than happy to help you.
                  </Typography>
               </Box>
            </Paper>
         </Grid>
      </Grid>
   );
};

export default Tester;
