import React, { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CallIcon from "@mui/icons-material/Call";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { AppBar, Drawer, Toolbar, List, ListItemButton, ListItemText } from "@mui/material";
import logo from "./Logo-concept Mototrs.png";

const pages = ["Home", "Pick Car", "Review", "Contact us"];

const logoStyle = {
   width: "170px",
   height: "auto",
   cursor: "pointer",
};

const AppBarStyled = styled(AppBar)(({ theme }) => ({
   zIndex: theme.zIndex.drawer + 1,
   // minWidth: 0,
   // width: "100%",
   boxShadow: 0,
   bgcolor: "transparent",
   backgroundImage: "none",
   mt: 2,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
   alignItems: "flex",
   padding: 0,
   // width: "100%",

   [`@media (min-width: 750px)`]: {
      maxHeight: 128,
   },

   [`@media (max-width: 750px)`]: {
      maxHeight: 80,
   },
}));

const DrawerBox = styled(Box)(({ theme }) => ({
   [`@media (min-width: 750px)`]: {
      display: "none", // Adjusted maxHeight for custom large screens
   },
   [`@media (max-width: 750px)`]: {
      display: "flex",
   },
}));
const StyledBox = styled(Box)(({ theme }) => ({
   [`@media (min-width: 750px)`]: {
      display: "flex", // Adjusted maxHeight for custom large screens
   },
   [`@media (max-width: 750px)`]: {
      display: "none",
   },
}));

const DrawerStyled = styled(Drawer)(({ theme }) => ({
   "& .MuiDrawer-paper": {
      top: "80px",
   },
}));

function NavBar() {
   const theme = useTheme();

   const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const boxSize = {
      height: isMediumScreen || isSmallScreen ? 34 : 93,
      width: isMediumScreen || isSmallScreen ? 34 : 78,
   };

   const [openDrawer, setOpenDrawer] = useState(false);

   const handleDrawerToggle = () => {
      setOpenDrawer(!openDrawer);
   };

   return (
      <AppBarStyled position="fixed" width={""}>
         <Container maxWidth="xl">
            <StyledToolbar
               variant="regular"
               sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexShrink: 0,
                  borderRadius: "999px",
                  bgcolor:
                     theme.palette.mode === "light"
                        ? "rgba(255, 255, 255, 0.4)"
                        : "rgba(0, 0, 0, 0.4)",
                  backdropFilter: "blur(24px)",
                  maxHeight: 40,
                  border: "1px solid",
                  borderColor: "divider",
                  boxShadow:
                     theme.palette.mode === "light"
                        ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                        : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
               })}
            >
               {/*  the LOGO */}

               <Box
                  {...boxSize}
                  my={4}
                  display="flex"
                  alignItems="center"
                  gap={4}
                  p={2}
                  // sx={{ backgroundColor: "white" }}
               >
                  <img src={logo} width={"150%"} />
               </Box>

               {/* Drawer for small Window */}

               <DrawerBox
                  sx={{
                     flexGrow: 1,

                     flexDirection: "row-reverse",
                  }}
               >
                  <IconButton
                     aria-label="menu"
                     aria-controls="menu-appbar"
                     aria-haspopup="true"
                     onClick={handleDrawerToggle}
                     color="inherit"
                  >
                     <MenuIcon sx={{ fontSize: 40 }} />
                  </IconButton>
               </DrawerBox>

               <DrawerStyled
                  anchor="top"
                  open={openDrawer}
                  onClose={handleDrawerToggle}
                  transitionDuration={{ enter: 900, exit: 600 }}
               >
                  <List>
                     {pages.map((page) => (
                        <ListItemButton key={page} onClick={handleDrawerToggle}>
                           <ListItemText primary={page} />
                        </ListItemButton>
                     ))}
                  </List>
               </DrawerStyled>

               {/*  Nav Item for Lg medium */}

               <StyledBox
                  height={128}
                  display="flex"
                  justifyContent="space-between"
                  sx={{
                     flexDirection: "column",
                     flexGrow: 1,
                  }}
               >
                  <Box
                     sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "row-reverse",
                     }}
                  >
                     <Box display={"flex"}>
                        <Box alignContent={"center"}>
                           <IconButton
                              aria-label="menu"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              color="inherit"
                           >
                              <CallIcon sx={{ fontSize: 38 }} />
                           </IconButton>
                        </Box>
                        <Box alignContent={"center"}>
                           <Typography variant="h6">+44 7376 685270</Typography>
                        </Box>
                     </Box>
                  </Box>

                  <Box
                     height={10}
                     sx={{
                        alignContent: "left",
                        display: "flex",
                        flexDirection: "row-reverse",
                        flexGrow: 1,
                     }}
                  >
                     <List sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                        {pages.map((page) => (
                           <ListItemButton key={page}>
                              <ListItemText primary={page} />
                           </ListItemButton>
                        ))}
                     </List>
                  </Box>
               </StyledBox>
            </StyledToolbar>
         </Container>
      </AppBarStyled>
   );
}
export default NavBar;
