import React from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const dropdownOptions = [
   { value: "", label: "None" }, // Option for null value
   { value: "option1", label: "BMW" },
   { value: "option2", label: "Mercedez" },
   { value: "option3", label: "Ford" },
   { value: "option4", label: "Mazda" },
   { value: "option5", label: "Honda" },
   { value: "option6", label: "Nissan" },
   { value: "option7", label: "Audi" },
];
const dropdownPrice = [
   { value: "", label: "None" }, // Option for null value
   { value: "option1", label: "£500" },
   { value: "option2", label: "£1000" },
   { value: "option3", label: "£1500" },
   { value: "option4", label: "£2000" },
   { value: "option5", label: "£2500" },
   { value: "option6", label: "£3000" },
   { value: "option7", label: "£4000" },
];

const Tester = () => {
   return (
      <Box
         mt={"10px"}
         width={"100%"}
         display="flex"
         flexDirection={{ xs: "column", sm: "row" }}
         flexWrap="wrap"
         justifyContent="center"
         alignItems="center"
         gap={1}
      >
         <FormControl sx={{ maxWidth: 180, width: "100%" }}>
            <InputLabel id="dropdown1-label">Make</InputLabel>
            <Select
               labelId="dropdown1-label"
               label="Dropdown 1"
               variant="outlined"
               sx={{ backgroundColor: "white" }}
            >
               {dropdownOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                     {option.label}
                  </MenuItem>
               ))}
            </Select>
         </FormControl>

         <FormControl sx={{ maxWidth: 180, width: "100%" }}>
            <InputLabel id="dropdown2-label">Modal</InputLabel>
            <Select
               labelId="dropdown2-label"
               label="Dropdown 2"
               variant="outlined"
               sx={{ backgroundColor: "white" }}
            >
               {dropdownOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                     {option.label}
                  </MenuItem>
               ))}
            </Select>
         </FormControl>

         <FormControl sx={{ maxWidth: 180, width: "100%" }}>
            <InputLabel id="dropdown3-label">Price</InputLabel>
            <Select
               labelId="dropdown3-label"
               label="Dropdown 3"
               variant="outlined"
               sx={{ backgroundColor: "white" }}
            >
               {dropdownPrice.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                     {option.label}
                  </MenuItem>
               ))}
            </Select>
         </FormControl>

         <Button
            variant="contained"
            color="primary"
            sx={{ width: 180, height: 45, backgroundColor: "#8B4513" }} // Adjust the color as needed
         >
            Submit
         </Button>
      </Box>
   );
};

export default Tester;
