import { Grid, Paper, Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import partEx from "./pics/part-ex.png";
import reserve from "./pics/reserve.png";
import warrenty from "./pics/free-warranty.png";
import review from "./pics/reviews.png";

const Services = () => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
   const isLgScreen = useMediaQuery(theme.breakpoints.down("lg"));

   const getWidth = () => {
      switch (true) {
         case isSmallScreen:
            return "100%";
         case isMdScreen:
            return "660px";
         case isLgScreen:
            return "860px";
         default:
            return "1050px";
      }
   };

   return (
      <Box display={"flex"} mt={3} justifyContent={"center"} width={"100%"}>
         <Grid container width={getWidth()} spacing={2} px={isSmallScreen ? 4 : 0}>
            <Grid item xs={12} sm={5}>
               <Paper
                  elevation={10}
                  sx={{
                     maxWidth: "100%",
                     height: isSmallScreen ? "240px" : "300px",
                     overflow: "hidden",
                     display: "flex",
                     backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.9) 5%, transparent), url(${partEx})`, // Updated background image
                     backgroundSize: "cover",
                     backgroundPosition: "center",
                     flexDirection: "column",
                     justifyContent: "flex-start",
                     alignItems: "flex-start",
                     p: 2,
                  }}
               >
                  <Box display={"flex"} flexDirection={"column"} width={"70%"}>
                     <Typography variant="h5" fontWeight={"bold"} color={"white"}>
                        Part Exchange
                     </Typography>
                     <Typography variant="subtitle1" color={"white"}>
                        We acquire pre-owned vehicles, and we employ a straightforward and effective
                        method for doing so
                     </Typography>
                  </Box>
               </Paper>
            </Grid>
            <Grid item xs={12} sm={7}>
               <Paper
                  elevation={10}
                  sx={{
                     display: "flex",
                     maxWidth: "100%",
                     overflow: "hidden",
                     backgroundImage: `linear-gradient(to left, rgba(0,0,0,0.9) 5%, transparent),url(${reserve})`,
                     backgroundSize: "cover",
                     backgroundPosition: "center",
                     flexDirection: "column",
                     justifyContent: "flex-start",
                     alignItems: "flex-end",
                     height: isSmallScreen ? "240px" : "300px",
                     p: 2,
                  }}
               >
                  <Box display={"flex"} flexDirection={"column"} width={"70%"} textAlign={"right"}>
                     <Typography variant="h5" fontWeight={"bold"} color={"white"}>
                        Reserve Online
                     </Typography>
                     <Typography variant="subtitle1" color={"white"}>
                        With our reserve online option, you needn't worry about that dream vehicle
                        slipping through your fingers, as we'll hold it for you and you only
                     </Typography>
                  </Box>
               </Paper>
            </Grid>
            <Grid item xs={12} sm={7}>
               <Paper
                  elevation={10}
                  sx={{
                     display: "flex",
                     maxWidth: "100%",
                     overflow: "hidden",
                     backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.9) 5%, transparent),url(${warrenty})`,
                     backgroundSize: "cover",
                     backgroundPosition: "center",
                     flexDirection: "column",
                     justifyContent: "flex-end",
                     alignItems: "flex-start",
                     height: isSmallScreen ? "240px" : "300px",
                     p: 2,
                  }}
               >
                  <Box display={"flex"} flexDirection={"column"} width={"70%"}>
                     <Typography variant="h5" fontWeight={"bold"} color={"white"}>
                        Warranty
                     </Typography>
                     <Typography variant="subtitle1" color={"white"}>
                        We take responsibility for ensuring the continuity of good service by
                        offering our own in-house warranties
                     </Typography>
                  </Box>
               </Paper>
            </Grid>
            <Grid item xs={12} sm={5}>
               <Paper
                  elevation={10}
                  sx={{
                     display: "flex",
                     maxWidth: "100%",
                     overflow: "hidden",
                     backgroundImage: `linear-gradient(to left, rgba(0,0,0,0.9) 5%, transparent),url(${review})`,
                     backgroundSize: "cover",
                     backgroundPosition: "center",
                     flexDirection: "column",
                     justifyContent: "flex-end",
                     alignItems: "flex-end",
                     height: isSmallScreen ? "240px" : "300px",
                     p: 2,
                  }}
               >
                  <Box display={"flex"} flexDirection={"column"} width={"80%"} textAlign={"right"}>
                     <Typography variant="h5" fontWeight={"bold"} color={"white"}>
                        5 Stars Reviews
                     </Typography>
                     <Typography variant="subtitle1" color={"white"}>
                        20+ 5 Stars Reviews on Autotrader
                     </Typography>
                  </Box>
               </Paper>
            </Grid>
         </Grid>
      </Box>
   );
};

export default Services;
