import { Box, Container, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import bg from "./pics/bg.jpeg";
import { Form } from "react-router-dom";
import HomeForm from "./HomeForm";
import CarouselHome from "./Carousel";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Services2 from "./Services2";

const ContainerStyled = styled(Container)(({ theme }) => ({
   padding: 0,
   [`@media (min-width: 600px)`]: {
      padding: 0,
      maxWidth: "100%",
   },
}));

const Home = () => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const isScreen = useMediaQuery(theme.breakpoints.down("750"));

   return (
      <ContainerStyled>
         <Box
            // mt={isScreen ? 10 : 15}
            display={"flex"}
            height={isSmallScreen ? 600 : 700}
            sx={{
               backgroundImage: `url(${bg})`,
               backgroundSize: "cover",
               backgroundPosition: "center",
               flexDirection: "column",
               justifyContent: "center",
               alignItems: "center",
            }}
         >
            <Typography
               // noWrap={true}
               color="white"
               // variant={isSmallScreen ? "h6" : "h2"}
               fontSize={isSmallScreen ? "22px" : "40px"}
               sx={{ fontWeight: "bold" }}
            >
               Welcome to CONCEPT MOTORS
            </Typography>
            <Typography color="white" variant={isSmallScreen ? "h6" : "h4"} sx={{ fontWeight: "" }}>
               Quality Cars in London
            </Typography>
            <HomeForm />
         </Box>
         <CarouselHome />
         <AboutUs />
         <Services />
         {/* <Services2 /> */}
      </ContainerStyled>
   );
};

export default Home;
