import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import React from "react";
import carInfo from "./carInfo.json";
import PdCard from "./PdCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const CarouselHome = () => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

   const getWidth = () => {
      switch (true) {
         case isSmallScreen:
            return 1;
         case isMdScreen:
            return 2;

         default:
            return 3;
      }
   };
   const slidesToShowValue = getWidth();
   const settings = {
      dots: true,
      Infinity: true,
      speed: 1000,
      slidesToShow: slidesToShowValue,
      slidesToScroll: slidesToShowValue,
   };

   return (
      <>
         <Box
            height={"460px"}
            width={"100%"}
            sx={{
               textAlign: "center",
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
            }}
         >
            <Typography fontWeight={"bold"} variant={isSmallScreen ? "h4" : "h2"}>
               Featured Vehicals
            </Typography>
            <Box maxWidth={"80%"} sx={{ alignItems: "center" }}>
               {/* <Carousel
                  animation="slide"
                  navButtonsAlwaysVisible
                  navButtonsProps={{ style: { backgroundColor: "black" } }}
                  indicators={true}
                  interval={5000}
                  index={3}
                  clickToChange
                  navButtonsWrapperProps={{
                     style: { justifyContent: "space-between", marginTop: "20px" },
                  }}
                  sx={{ mt: 1, px: 10 }}
               >
                  {carInfo.map((car, index) => (
                     <PdCard
                        key={index}
                        title={car.title}
                        imgSrc={car.pic}
                        price={car.price}
                        info={car.info}
                     />
                  ))}
               </Carousel> */}
               <Slider {...settings}>
                  {carInfo.map((car, index) => (
                     <PdCard
                        key={index}
                        title={car.title}
                        imgSrc={car.pic}
                        price={car.price}
                        info={car.info}
                     />
                  ))}
               </Slider>
            </Box>
         </Box>
      </>
   );
};

export default CarouselHome;

// import React, { useRef } from "react";
// import { Grid, Paper, Typography, Box, Button, styled } from "@mui/material";
// import PdCard from "./PdCard";

// const CarouselContainer = styled(Grid)({
//    position: "relative",
//    overflow: "hidden",
// });

// const CarouselTrack = styled(Grid)({
//    display: "flex",
//    transition: "transform 0.5s ease",
// });

// const CarouselButton = styled(Button)({
//    position: "absolute",
//    top: "50%",
//    transform: "translateY(-50%)",
//    backgroundColor: "transparent",
//    border: "none",
//    cursor: "pointer",
//    zIndex: 1,
// });

// const Carousel = () => {
//    const trackRef = useRef(null);

//    const handleNext = () => {
//       trackRef.current.style.transform = `translateX(-100%)`;
//    };

//    const handlePrev = () => {
//       trackRef.current.style.transform = `translateX(0)`;
//    };

//    return (
//       <CarouselContainer container>
//          <CarouselButton style={{ left: 0 }} onClick={handlePrev}>
//             {"<"}
//          </CarouselButton>
//          <CarouselButton style={{ right: 0 }} onClick={handleNext}>
//             {">"}
//          </CarouselButton>
//          <CarouselTrack ref={trackRef}>
//             {carsInfo.map((car, index) => (
//                <Grid item key={index}>
//                   <PdCard
//                      key={index}
//                      title={car.title}
//                      imgSrc={car.pic}
//                      price={car.price}
//                      info={car.info}
//                   />
//                </Grid>
//             ))}
//          </CarouselTrack>
//       </CarouselContainer>
//    );
// };

// export default Carousel;
